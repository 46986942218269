import { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import logo from "../../assets/ucSKILLS-logo-header.svg";
import { getUserFullName, getUserResellerName, getUserResellerType, ResellerType } from "./../../utils/getUserInformation";
import useLocalStorage from "./../../hooks/useLocalStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faStar, faPoundSign, faBarChart, faBoxesPacking, faUser, faX, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import "./NavTop.scss";

const NavTop = () => {
	const [updateStorage, setUpdateStorage] = useState(false);
	window.addEventListener("ap-liu-update-nav", () => { setUpdateStorage(true); }, false);

	const [master, setMaster] = useState(false);
	const [nonBillable, setNonBillable] = useState(false);
	const [user, setUser] = useState("");
	const [reseller, setReseller] = useState("");
	const location = useLocation();

	useLocalStorage("ap-liu", () => {
		setMaster(getUserResellerType() === ResellerType.iTEL);
		setNonBillable(getUserResellerType() === ResellerType.NonBillable);
		setUser(getUserFullName());
		setReseller(getUserResellerName());
		setUpdateStorage(false);
	}, [location, updateStorage]);

	const [dropDownIsOpen, setDropDownIsOpen] = useState(false);
	const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
	const { instance } = useMsal();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const reports = false;

	const dropDownToggle = () => {
		setDropDownIsOpen((dropDownIsOpen) => !dropDownIsOpen);
	};

	const mobileMenuToggle = () => {
		setMobileMenuIsOpen((mobileMenuIsOpen) => !mobileMenuIsOpen);
	};

	const setActiveClass = (isActive) => {
		return (isActive) ? "active" : "none";
	};

	const logout = () => {
		localStorage.removeItem("ap-liu");
		instance.logout();
	};

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []); 

	useEffect(() => {
		setMobileMenuIsOpen(false);
	}, [location]);

	return (
		<header className={windowWidth > 1000 ? "topNav" : "topNavMobile"}>
			{/*Backdrop for mobile menu*/}
			<div className={`topNav_backdrop ${mobileMenuIsOpen ? "topNav_backdrop_open" : ""}`}></div>
			{/*Drop Down*/}
			<div className={`topNav_dropDown topNav_dropDown_side ${dropDownIsOpen ? "topNav_dropDown_open" : ""} ${mobileMenuIsOpen ? "topNav_dropDown_openMobile" : ""}`}>
				<div className="topNav_dropDown_text">
					<p className="topNav_dropDown_text_item"><b>{user}</b></p>
					<p className="topNav_dropDown_text_item">{reseller}</p>
				</div>
				<div className="topNav_dropDown_icons">
					<span onClick={logout} className="topNav_dropDown_icons_icon font_s">Logout <FontAwesomeIcon icon={faRightFromBracket} /></span>
				</div>
			</div>
			{/*Top Bar*/}
			<div className="topNav_grid">
				<div className="topNav_logo">
					<Link to="/"><img alt="UCSkills" src={logo} /></Link>
				</div>
				<div className={`topNav_links font_s ${mobileMenuIsOpen ? "topNav_links_open" : ""}`}>
					{
						!master && <NavLink to="/customers" className={(navData) => setActiveClass(navData.isActive)}>
							<FontAwesomeIcon icon={faStar} />
							ucSKILLS Access
						</NavLink>
					}
					<NavLink to="/teams" className={(navData) => setActiveClass(navData.isActive)}>
						<FontAwesomeIcon icon={faUsers} />
						Team
					</NavLink>
					<NavLink to="/resellers" className={(navData) => setActiveClass(navData.isActive)}>
						<FontAwesomeIcon icon={faBoxesPacking} />
						Resellers
					</NavLink>
					{
						!nonBillable && < NavLink to="/billing" className={(navData) => setActiveClass(navData.isActive)}>
							<FontAwesomeIcon icon={faPoundSign} />
							Billing
						</NavLink>
					}
					{
					reports && <NavLink to="/reports" className={(navData) => setActiveClass(navData.isActive)}>
						<FontAwesomeIcon icon={faBarChart} />
						Reports
					</NavLink>
					}
					{
					master && <NavLink to="/provisioning" className={(navData) => setActiveClass(navData.isActive)}>
						<FontAwesomeIcon icon={faStar} />
						Provisioning
					</NavLink>
					}
				</div>
				<div className={`topNav_icon ${dropDownIsOpen ? "topNav_icon_active" : ""}`} onClick={dropDownToggle}>
					<FontAwesomeIcon className={`topNav_icon_image ${!dropDownIsOpen ? "topNav_icon_image_active" : ""}`} icon={faUser} />
					<FontAwesomeIcon className={`topNav_icon_image ${dropDownIsOpen ? "topNav_icon_image_active" : ""}`} icon={faX} />
				</div>
				<div className={`topNav_hamburger ${mobileMenuIsOpen ? "topNav_hamburger_open" : ""}`} onClick={mobileMenuToggle}>
					<span className="topNav_hamburger_barOne"></span>
					<span className="topNav_hamburger_barTwo"></span>
					<span className="topNav_hamburger_barThree"></span>
				</div>
			</div>
		</header>
	);
}

export default NavTop;
