import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { getTimestamp, updateTimestamp } from "../../../utils/getUserInformation";

/**
 * @readonly
 * @enum {number}
 */
export const LogoutReason = {
	None: 1,
	OtherTab: 2,
	Expired: 3
};

export const useSession = () => {
	const TIMEOUT_SECONDS = 3600;
	const { instance } = useMsal();
	const [reason, setReason] = useState(LogoutReason.None);

	useEffect(() => {
		const lastActivity = getTimestamp();
		const account = instance.getActiveAccount();
		if (account === null) {
			setReason(LogoutReason.OtherTab);
		}
		else if (lastActivity > 0) {
			let currentActivity = (Math.floor(new Date().getTime() * 0.001));
			const result = (lastActivity + TIMEOUT_SECONDS) < currentActivity;
			if (!result) {
				updateTimestamp(currentActivity);
			}
			else {
				setReason(LogoutReason.Expired);
			}
		}
	}, []);

	return {
		reason: reason
	};
};